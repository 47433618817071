import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeader from '../../components/pageheader/pageheader'
import Section from '../../components/section/section'
import Ctf2021Image from '../../images/promotions/ctf2021.png'

const CTFPage = () => {
  return (
    <Layout>

      <PageHeader title="Capture the Flag Promotion" />

      <Section color="white">
        <div className="container">
          <div className="row align-items-end justify-content-center text-center">
            <img src={Ctf2021Image} className="w-100 img-responsive" alt="" />
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default CTFPage
